import React from "react";
import styled from "styled-components";

const ContentContainer = styled.div``;

function NoContentMessage() {
  return <ContentContainer>Loading</ContentContainer>;
}

export default NoContentMessage;
