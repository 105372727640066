module.exports = {
  siteMetadata: {
    title: `Leeds United Supporters' Trust`,
    description: `The Leeds United Supporters' Trust is a democratic, non-for-profit organization, which believe that as fans, we're stronger together.`,
    author: `@cawthornejosh`,
    siteUrl: "https://lufctrust.com",
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/assets/images`,
      },
    },
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "EYO18H7VFdEyFv9ODW9Pywtt",
        homeSlug: "home",
        resolveRelations: [
          `article.author`,
          `article.postCategories`,
          `articleRoot.featuredArticle`,
          `articleRoot.featuredArticle.content.author`,
        ],
        version: "published",
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-styled-components`,
    `gatsby-plugin-preload-fonts`,
    "gatsby-plugin-react-svg",
    `gatsby-plugin-sitemap`,
    `gatsby-plugin-sharp`,
    `@wardpeet/gatsby-image-nextgen`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `leeds-united-supporters-trust`,
        short_name: `lust`,
        start_url: `/`,
        background_color: `#2C3445`,
        theme_color: `#2C3445`,
        display: `minimal-ui`,
        icon: `src/assets/images/favicon-upd.jpg`,
      },
    },
    `gatsby-plugin-sass`,
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-113215509-2",
      },
    },
    {
      resolve: `gatsby-plugin-polyfill-io`,
      options: {
        features: [`Array.prototype.map`, `fetch`],
      },
    },
  ],
};
