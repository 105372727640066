import React from "react";

import SbEditable from "storyblok-react";
import config from "../../gatsby-config";
import Layout from "../components/layout";
import StoryblokComponent from "../components/storyblok/storyblok-component";
import NoContentMessage from "../components/no-content-message";

let sbConfigs = config.plugins.filter((item) => {
  return item.resolve === "gatsby-source-storyblok";
});
let sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {};

const loadStoryblokBridge = function (cb) {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`;
  script.onload = cb;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const getParam = function (val) {
  let result = "";
  let tmp = [];

  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1]);
      }
    });

  return result;
};

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { story: null };
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();

      // window.storyblok.on(['published', 'change'], function(event) {
      //   if (!event.slugChanged) {
      //     location.reload(true);
      //   }
      // });
    });
  }

  loadStory() {
    window.storyblok.get(
      {
        slug: getParam("path"),
        version: "draft",
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ story: data && data.story });
      }
    );
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam("path") });

    let sb = window.storyblok;

    sb.on(["change", "published"], (payload) => {
      this.loadStory(payload);
    });

    sb.on("input", (payload) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        );
        this.setState({ story: payload.story });
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }

  render() {
    if (this.state.story == null) {
      return <NoContentMessage />;
    }

    const { name: pageName, content } = this.state.story;
    const isNavigationComponent = content.component === "global_navigation";
    const isFooterComponent = content.component === "footer";

    return (
      <Layout
        title={"Editor"}
        navigationEditorContent={isNavigationComponent && content}
        footerEditorContent={isFooterComponent && content}
      >
        <SbEditable content={content}>
          <div>
            {!(isNavigationComponent || isFooterComponent) && (
              <StoryblokComponent {...content} pageName={pageName} />
            )}
          </div>
        </SbEditable>
      </Layout>
    );
  }
}

export default StoryblokEntry;
